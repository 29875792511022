import React from "react"

import Home from "../components/home"
import FLayout from "../components/flayout/index.js";

const IndexPage = ({data}) => (
  <FLayout seoFields={seoFields}>
    <Home homeContent={data.allContentJson.edges[0].node.homeContent} data={data}/>
  </FLayout>
)

const seoFields = {
  description: "MProfit - India's best portfolio tracking app for Stocks, Mutual Funds, Bonds, NPS, PPF & more. Auto-import data, get Capital Gains, XIRR & other reports. Try free today!",
  // keywords,
  title: "Multi-asset Portfolio Tracker for Investors, Chartered Accountants and Financial Advisors",
  mprofitBefore: true
}

export const query = graphql`
query homeContentQuery{
  allContentJson {
    edges {
      node {
        homeContent {
          banner {
            headline
            text
          }
          feature {
            headline
            text
            list {
              order
              text
              graphic
              featureOrder
            }
          }
          builtFor {
            headline
            text
          }
          clients {
            headline
            text
          }
          clientTypes {
            name
            order
            image
            slug
          }
        }
      }
    }
  }
  featuredReviews: allWordpressWpReview(
    filter: { acf: { featured: {eq: true}}}
  ){
    edges {
      node {
        id
        title
        acf {
          featured
        }
        excerpt
        review_cat
        content
        date
      }
    }
  }
  reviewCategs: allWordpressWpReviewCat {
    edges {
        node {
            wordpress_id
            slug
        }
    }
  }
}
`

export default IndexPage
