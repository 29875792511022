import React from "react"
import { StaticQuery, graphql } from "gatsby"
import LogoItem from "../logoItem";
import Slider from "react-slick";
import { SliderNextArrow, SliderPrevArrow } from "../../shared/sliderArrows";

const HomeLogos = ({type}) => (
  <StaticQuery
    query={graphql`
      query {
        jm_financial: file(relativePath: { eq: "logos/JM Financial.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        ambavat_jain: file(relativePath: { eq: "logos/Ambavat Jain.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        capitalmind: file(relativePath: { eq: "logos/Capitalmind.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        d_kothary: file(relativePath: { eq: "logos/D Kothary.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        shaman_group: file(relativePath: { eq: "logos/Shaman Group.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        kredent_group: file(relativePath: { eq: "logos/Kredent Group.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        lalkar: file(relativePath: { eq: "logos/Lalkar Group.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        mangal_keshav: file(relativePath: { eq: "logos/Mangal Keshav.jpg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        nangia_advisors: file(relativePath: { eq: "logos/Nangia Advisors.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        oswal_securities: file(relativePath: { eq: "logos/Oswal Securities.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        pidilite: file(relativePath: { eq: "logos/Pidilite.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        shah_investors: file(relativePath: { eq: "logos/Shah Investors.PNG" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        samvitti_capital: file(relativePath: { eq: "logos/Samvitti Capital.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        shemaroo_group: file(relativePath: { eq: "logos/Shemaroo Group.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        vls_finance: file(relativePath: { eq: "logos/VLS Finance.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        bharat_forge: file(relativePath: { eq: "logos/Bharat Forge.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        trade_smart: file(relativePath: { eq: "logos/Trade Smart.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        cgs_cimb: file(relativePath: { eq: "logos/CGS CIMB.jpeg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        barclays: file(relativePath: { eq: "logos/Barclays.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        khimji_kunverji: file(relativePath: { eq: "logos/Khimji Kunverji.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
        type = type || 'ambavat_jain';
        const colCount = homePageLogos.length/2;
        // const typeCamelCase = type.replace("-","_");
        const items = [];
        for(var i = 0; i<colCount; i++) {
            var item1 = i*2;
            var item2 = item1+1;
            items.push(
                <div className="col" key={i}> 
                    <div className="imgbox">
                        <LogoItem imgData={data[homePageLogos[item1]]}/>
                        <LogoItem imgData={data[homePageLogos[item2]]}/>
                    </div>
                </div>
            )
        }
        return (
            <>
            <Slider {...settingsLogos}>
            {
                items
            }
            </Slider>
            </>
        )
    }}
  />
)
export default HomeLogos

const homePageLogos = [
  'barclays',
  'shemaroo_group',
  'cgs_cimb',
  'trade_smart',
  'jm_financial',
  'bharat_forge',
  'pidilite',
  'capitalmind',
  'shaman_group',
  'lalkar',
  'mangal_keshav',
  'vls_finance',
  'oswal_securities',
  'd_kothary',
  'ambavat_jain',
  'nangia_advisors',
  'shah_investors',
  'samvitti_capital',
  'khimji_kunverji',
  'kredent_group'
]


const settingsLogos = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            dots: false
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            dots: true
          }
        }
      ]

  }
