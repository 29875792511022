import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HomeFeatureGraphics = ({type}) => (
  <StaticQuery
    query={graphql`
      query {
        reports: file(relativePath: { eq: "graphics/feature-reports/single/graphic.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        all_investments: file(relativePath: { eq: "graphics/feature-all-investments/single/graphic.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        import: file(relativePath: { eq: "graphics/feature-import/single/graphic.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        capgain_xirr: file(relativePath: { eq: "graphics/feature-capgain-xirr/single/graphic.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
        const typeCamelCase = type.replace("-","_");
        const imgData = data[typeCamelCase];
        return (
                <Img fluid={imgData.childImageSharp.fluid} imgStyle={{'objectFit': 'contain',height:'90%'}} alt={home_feature_alts[typeCamelCase]}/>
        )
    }}
  />
)
export default HomeFeatureGraphics

const home_feature_alts = {
  'reports': 'Gain insights with MProfit Reports',
  'all_investments': 'Manage and track multiple asset classes',
  'import': 'Auto-import data from 4,000+ file formats across 700+ brokers',
  'capgain_xirr': 'Get Capital Gains in Income-Tax-Return format for Stocks, Mutual Funds & Bonds'
}
