import React from "react"
import styles from "./index.module.css";
import FeatureIcon from "../../images/icon1.inline.svg"
import Investors from "../../images/Investors.inline.svg"
import FinancialAdvisors from "../../images/FinancialAdvisors.inline.svg"
import CharteredAccountants from "../../images/CharteredAccountants.inline.svg"
import FamilyOffices from "../../images/FamilyOffices.inline.svg"
import TryForFree from "../tryForFree";
import HomeTopGraphic from "../../gatsby-images/home-top-graphic";
import ReviewsSlider from "../reviewsSlider";
import {  Link, navigate } from "gatsby"
import GreenCurve from '../../gatsby-images/green-curve';
import DotsSquare from "../../gatsby-images/dots-square";
import FPattern from "../../gatsby-images/f-pattern";
import HomeFeatureGraphics from "../../gatsby-images/home-feature-graphics";
import HomeLogos from "../homeLogos";
import BlueDotsPattern from "../../gatsby-images/blue_dot_pattern";
import { getHomeFeaturedReviews } from "../../common/gFunctions";
import Metrics from "../../shared/metrics";
import FeaturedIn from "../featuredIn";
import GoogleReviewsSlider from "../googleReviewsSlider";

const clientTypeImages = {
    Investors: <Investors/>, FinancialAdvisors: <FinancialAdvisors/>, CharteredAccountants: <CharteredAccountants/>, FamilyOffices: <FamilyOffices/>
}

class Home extends React.Component {

    constructor(props)
    {
        super(props);

        const reviewCategs = this.props.data.reviewCategs.edges;
        const financialAdvisors = reviewCategs.find(x => x.node.slug === 'financial-advisors').node.wordpress_id;
        const charteredAccountants = reviewCategs.find(x => x.node.slug === 'chartered-accountants').node.wordpress_id;

        const reviewsList = getHomeFeaturedReviews(this.props.data.featuredReviews.edges,financialAdvisors,charteredAccountants);

        this.state = {
            feature: this.props.homeContent.feature.list[0],
            reviewsList,
            featuresHeight: 500,
        }
    }

    selectFeature = (feature) => {
        this.setState({ feature })
    }

    featuresHeight = (el) => {
        if (el && this.state.featuresHeight !== el.offsetHeight) {
            this.setState({featuresHeight: el.offsetHeight})
        }
    }

    render() {
        const homeContent = this.props.homeContent;
        return (
            <> 
                <section className={styles.home_banner_area}>
		            <div className={styles.backgroundCurve}>
                        <GreenCurve/>
                    </div>
                        <div className={[styles.banner_inner, "owl-carousel"].join(" ")}>
                            <div className={styles.singleWelcomeSlide}>
                                <div className={[styles.welcomeContent,  "h-100"].join(" ")}>
                                    <div className="container">
                                        <div className="row ">
                                            <div className={["col-12", "col-lg-5", "order-lg-2","mb-0"].join(" ")}>
                                                <div className={[styles.banner_content,"text-center", "text-lg-left"].join(" ")}>
                                                    <h2 className={["text-center","text-lg-left"].join(" ")}>{homeContent.banner.headline}</h2>  
                                                    <p>
                                                        {homeContent.banner.text}
                                                    </p>
                                                    <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                                                        <Link to="/sign-up" className={[styles.primary_btn, "mb-5","mb-lg-0"].join(" ")} >Try now for free</Link>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            
                                            <div className={["col-12", "col-lg-7", "order-md-1"].join(" ")}>
                                                <div className={[styles.bannerThumbnail].join(" ")}>
                                                    <HomeTopGraphic/>
                                                </div>
                                            </div>

                                            <div className={styles.SShape}>
                                                <DotsSquare/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
                
                <section className={[styles.features_area].join(" ")}>
                    <div className={["container",styles.metricsContainer].join(" ")}>
                        <div className="row">
                            <Metrics/>
                        </div>
                    </div>
                    <div className="container">
                        <div className={["row", "justify-content-center"].join(" ")}>
                            <div className={[styles.backgroundCurve1,"d-none","d-md-block"].join(' ')}>
                                <FPattern/>
                            </div>
                            <div className={["col-lg-8", "text-center"].join(" ")}>
                                <div className={[styles.main_title_h].join(" ")}>
                                    <h2 className={["text-center"].join(" ")}>{homeContent.feature.headline}</h2>
                                    <p>
                                    {homeContent.feature.text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[styles.feature_list_section,'mt-md-5'].join(' ')}>
                        <div className="container">
                            <div className={["row", "align-items-center"].join(" ")}>
                                <div className="col-lg-4"  ref={(el) => this.featuresHeight(el)}>
                                    {
                                        homeContent.feature.list.sort((x,y) => x.order<y.order ? -1 : 1)
                                        .map((feature, index) => <FeatureItem feature={feature} key={feature.order} selectFeature={this.selectFeature} index={index} activeFeature={this.state.feature}/>)
                                    }
                                    <div className="row justify-content-center justify-content-lg-start">
                                        <div className="mt-5">
                                            <Link to="/features" className='morebtn'>See more features  <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={["col-lg-8", "d-none", "d-lg-block","feature_img_box", styles.featureImg].join(" ")} style={{maxHeight: this.state.featuresHeight}}>
                                    <HomeFeatureGraphics type={this.state.feature.graphic}/>
                                </div>
                            </div>
                        </div>
                    </div>      
                </section>

                <FeaturedIn/>

                <BlueDotsPattern extraClassName={styles.blue_dot_background}>     
                    <section className={styles.about_info}>              
                        <div className="container">
                            <div className={[styles.title_text, "text-center", "row","justify-content-center"].join(" ")}>
                                <h2 className="text-center col-12">
                                    {homeContent.builtFor.headline}
                                </h2>
                                <p className="col-10 col-lg-8">
                                    {/* <strong>{homeContent.builtFor.bold}</strong> */}
                                    {homeContent.builtFor.text}
                                </p>
                            </div>
                            <div className={[styles.info_box, "text-center"].join(" ")}>
                                
                                    <div className="row">
                                        {
                                            homeContent.clientTypes.sort((x,y) => x.order<y.order ? -1 : 1)
                                                .map((clientType,index) => <ClientTypeItem clientType={clientType} key={clientType.order} index={index}/>)
                                        }
                                    </div>
                            </div>
                        </div>
                    </section>
                </BlueDotsPattern>
                
                <section className={styles.Logos_sec}>
                    <div className="container">
                        <div className={[styles.title_text, "text-center"].join(" ")}>
                            <h2 className="text-center">
                                {homeContent.clients.headline}
                            </h2>
                            <p>
                                {homeContent.clients.text}
                            </p>
                        </div>    
                        <div className={[styles.Logos_sec_info_box,"logos-sec", "text-center"].join(" ")}>
                            <HomeLogos/>
                        </div>
                        <div className="text-center mt-4">
                            {/* <Link to="/clients" className='morebtn'>View more clients  <i className="fa fa-angle-right" aria-hidden="true"></i></Link> */}
                        </div>
                    </div>
                </section>

                {/* <ReviewsSlider reviewsList={this.state.reviewsList}/> */}
                <GoogleReviewsSlider/>
                
                <TryForFree/>
                
                
            </>
        )
    }
}

const FeatureItem = ({feature, selectFeature, activeFeature}) => {
    return (
        <div className={[styles.single_feature, "row", "align-items-center",activeFeature.order === feature.order ? styles.single_feature_active : ''].join(" ")} onMouseEnter={()=> selectFeature(feature)} onClick={()=>navigate('/features',{state:{activeFeature:feature.featureOrder}})}>
            <div className={[styles.feature_right_img,"col-lg-8","text-md-right", "d-lg-none",styles.feature_img_mb,"feature_img_box"].join(' ')}>
                <HomeFeatureGraphics type={feature.graphic}/>
            </div>
            <div className={[styles.feature_head, "col-12", "col-sm-2","mb-3","mb-md-0"].join(" ")}>
                <span className={["lnr", "lnr-screen"].join(" ")}>
                    <FeatureIcon/>
                </span>
            </div>
            <div className={[styles.feature_content, "col-12", "col-sm-9"].join(" ")}>
                <p>{feature.text}</p>
            </div>
        </div>

    )
}

const goToClientPage = (clientType) => {
    navigate(clientType.slug);
}

const ClientTypeItem = ({clientType,index}) => {
    return (
        <div className={["col-12", "col-sm-6", "col-lg-3",styles.clientTypeContainer].join(" ")}>
            <div className={styles.info_item} onClick={() => {goToClientPage(clientType)}}>
                <div className={styles.imgbox}>
                    {
                        clientTypeImages[clientType.image]
                    }
                </div>
                <span className={styles.clientTypeName}>{clientType.name}<i className="fa fa-angle-right ml-2" aria-hidden="true"></i></span>
            </div>
        </div>
    )
}

export default Home