import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image";

const BlueDotsPattern = ({children,extraClassName}) => (
  <StaticQuery
    query={graphql`
      query {
        dots: file(relativePath: { eq: "blue_dot_pattern.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
        const dots = data.dots.childImageSharp.fluid
        return (
            <BackgroundImage 
                fluid={dots}
                className={extraClassName}
                style={{
                  backgroundRepeat: 'repeat',
                  backgroundSize: 'auto'
                }}
            >
                {children}
            </BackgroundImage>

        )}
    }
  />
)
export default BlueDotsPattern
