import React from "react"
import styles from "./styles.module.css";
import Slider from "react-slick";
import ReviewItem from "../../shared/reviewItem";
import {  Link } from "gatsby"
import { SliderNextArrow, SliderPrevArrow } from "../../shared/sliderArrows";
import ReviewModal from "../../shared/reviewModal";

class ReviewsSlider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            featuredReviews: this.props.reviewsList,
            defaultLength: 100,
            moadal: false,
            reviewContent: null
        }
    }

    shuffleArray(oldArray) {
        var array = [...oldArray]
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    toggleModal=() =>{
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }

    readMoreContent = (review) => {
        console.log('readMoreContent',review);
        this.setState({ 
            reviewContent: review,
            modal: true,
        });
    }

    render() {
        return (
            <>
                {this.state.modal && this.state.reviewContent && 
                    <ReviewModal isOpen={this.state.modal} toggleModal={this.toggleModal} review={this.state.reviewContent} showDate={false}/>
                }
                {/* slider */}
                <div className={[styles.reviewsSec,'reviews-sec'].join(' ')}>
                    <div className="container">
                        <Slider {...settings}>
                            {
                                this.state.featuredReviews.map((review,i) => {
                                    return (
                                        <div className="col-md-12" key={i}>
                                            <ReviewItem review={review.node} readMore={this.readMoreContent} showDate={false}/>
                                        </div>
                                    )
                                })
                            
                            }
                        </Slider>
                    </div>
                    <div className="text-center mt-5">
                        <Link to="/reviews" className='morebtn' href="#">See more reviews  <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
            </>
        )
    }
}

export default ReviewsSlider

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true,
              dots: false
            }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true
          }
        }
    ]

};