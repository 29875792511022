import React from "react"
import styles from "./styles.module.css";

import ET from "../../images/featured-in/et.png";
import Mint from "../../images/featured-in/mint.svg";
import Inc42 from "../../images/featured-in/inc42.svg";
import YourStory from "../../images/featured-in/yourstory.svg";
import VCCircle from "../../images/featured-in/vccircle.png";
import BWDisrupt from "../../images/featured-in/bwdisrupt.svg";
import StartupStory from "../../images/featured-in/startupstory.png";

const FeaturedInList = [
    {
        name: 'ET',
        logo: ET,
        link: 'https://economictimes.indiatimes.com/tech/funding/fintech-firm-mprofit-raises-2-million-in-funding/articleshow/97178340.cms',
        width: '80%'
    },
    {
        name: 'Mint',
        logo: Mint,
        link: 'https://www.livemint.com/companies/start-ups/mprofit-raises-2-mn-from-zerodha-s-rainmatter-enam-others-11674045972865.html'
    },
    {
        name: 'Inc42',
        logo: Inc42,
        link: 'https://inc42.com/buzz/portfolio-management-startup-mprofit-raises-funding-bring-features/'
    },
    {
        name: 'YourStory',
        logo: YourStory,
        link: 'https://yourstory.com/2023/01/startups-podeum-mprofit-socialboat-amama-secure-seed-funding'
    },
    {
        name: 'VCCircle',
        logo: VCCircle,
        link: 'https://www.vccircle.com/fintechstartup-mprofit-raises-fresh-funding'
    },
    {
        name: 'BWDisrupt',
        logo: BWDisrupt,
        link: 'https://bwdisrupt.businessworld.in/article/Fintech-Startup-MProfit-Secures-2-M-From-Rainmatter-Others/19-01-2023-462406/'
    },
    // {
    //     name: 'StartupStory',
    //     logo: StartupStory,
    //     link: 'https://startupstorymedia.com/insights-fintech-platform-mprofit-raises-2-million-from-zerodhas-rainmatter-enam-others/'
    // }
]

const FeaturedIn = ({}) => (
    <div className={styles.featuredInSection}>
        <div className={["container mb-4",styles.featuredInContainer].join(' ')}>
            <div className="row">
                <div className={["col-12", "text-center", "pt-0", styles.featuredInTitle].join(" ")}>
                    <h2 className={styles.featuredInText}>Featured in</h2>
                </div>
            </div>
            <div className={["row", "justify-content-center","align-items-center"].join(" ")}>
                {
                    FeaturedInList.map((featuredInItem) => {
                        return (
                            <div className={['col-lg-2 col-md-3 col-4','text-center',styles.featuredInItem].join(' ')}>
                                <a href={featuredInItem.link} target="_blank" rel="noopener noreferrer"><img src={featuredInItem.logo} width={featuredInItem.width} alt={featuredInItem.name}/></a>
                            </div>
                        );
                    })
                }
            </div>
            
        </div>
    </div>
)

export default FeaturedIn
