import React from "react";
import styles from "./styles.module.css";
import { StaticQuery } from "gatsby";

const Metrics = () => (
    <StaticQuery
    query={graphql`
        query {
            allContentJson {
                edges {
                    node {
                        homeContent {
                            metrics {
                                order
                                name
                                value
                            }
                        }
                    }
                }
            }
        }
    `}
    render = {data => 
        <>
        {
            data.allContentJson.edges[0].node.homeContent.metrics.sort((x,y) => x.order < y.order ? -1 : 1).map((metric,index) => {
                return (
                    <div className="col-md text-center mt-3  mt-md-0" key={index}>
                        <div className={styles.metricValue}>{metric.value}</div>
                        <p className={styles.metricName}>{metric.name}</p>
                    </div>
                )
            })
        }
        </>
    }
    />
)

export default Metrics