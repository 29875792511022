import React from "react";
import styles from "./styles.module.css";
import left_arrow from "../../images/left_arrow.png";
import right_arrow from "../../images/right_arrow.png";

export const SliderNextArrow = (props) => {
    const { onClick } = props;
    return (
        <span  className={[styles.carouselControlNext,'carousel-control-next'].join(' ')} role="button" onClick={onClick}>
            <img src={right_arrow} alt="" />
            <span className="sr-only">Next</span>
        </span>
    );
}

export const SliderPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <span className={[styles.carouselControlPrev,'carousel-control-prev'].join(' ')} role="button" onClick={onClick}>
            <img src={left_arrow} alt="" />
            <span className="sr-only">Previous</span>
        </span>
    );
}