import React from "react"
import styles from "./styles.module.css";
import BackgroundImage from 'gatsby-background-image'
import { StaticQuery, navigate } from "gatsby";

const TryForFree = ({isDesktop,recommendedState}) => (
    <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "CTA_Banner.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
        const imageData = data.desktop.childImageSharp.fluid
        return (
            <BackgroundImage 
                className={styles.try_free_section}
                fluid={imageData}
            >
                <div className="container">
                    <div className="row">
                        <div className={["col", "text-center"].join(" ")}>
                          <h2>Try MProfit for free</h2>
                          <button className={["btn", "btn-primary", "mt-4","rounded"].join(" ")} onClick={isDesktop ? () => goToDownload(recommendedState) : () => goToSignup(recommendedState)}>{isDesktop ? 'Download now' : 'Sign up now'}</button>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        //     <div className={styles.try_free_section}>
        //   <div className="container">
        //     <div className="row">
        //       <div className="col text-center">
        //         <h2>Try MProfit for free</h2>
        //         <button className="btn btn-primary mt-4 rounded">Try now</button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        )
    }}
    />
)

const goToDownload = (recommendedState) => {
  navigate('/sign-up', {state: recommendedState});
}

const goToSignup = (recommendedState) => {
  navigate('/sign-up', {state: recommendedState});
}

export default TryForFree
